import { ThemeItem } from '../../models/theme-item';

const Navigation = ({
  items,
  activeIndex,
  setActiveIndex
}: {
  items: ThemeItem[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) => {
  return (
    <div className="navigation">
      <p className="navigation__choose">Scegli il tema che preferisci</p>
      <p className="navigation__title">{items[activeIndex]?.title}</p>
      <div className="navigation__items">
        {items.map((item, index) => (
          <div
            key={index}
            className={`selector my-2 ${
              index === activeIndex ? ' active' : ''
            }`}
            onClick={() => setActiveIndex(index)}
          >
            <span
              className="selector__dot mx-2"
              style={{
                backgroundColor:
                  index === activeIndex ? item.primaryColor : 'transparent',
                borderColor: item.primaryColor
              }}
            ></span>
            <h5 className="selector__label mb-0">{item.title}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Navigation;
