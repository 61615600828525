import { SlideItem } from '../../models/slide-item';

const Navigation = ({
  slides,
  activeIndex,
  viewSlide
}: {
  slides: SlideItem[];
  activeIndex: number;
  viewSlide: (index: number) => void;
}) => {
  return (
    <>
      <div className="navigation">
        {slides.map((slide, index) => (
          <span
            key={index}
            className={`mx-1 navigation__dot${
              index === activeIndex ? ' active' : ''
            }`}
            onClick={() => viewSlide(index)}
          ></span>
        ))}
      </div>
    </>
  );
};

export default Navigation;
