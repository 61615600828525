import { ThemeItem } from '../../models/theme-item';
import frameImg from './../../assets/device-frame-iphone.png';

const Device = ({
  items,
  activeIndex,
  targetImg
}: {
  items: ThemeItem[];
  activeIndex: number;
  targetImg: 'primary' | 'secondary';
}) => {
  return (
    <div className="device">
      <img className="frame" src={frameImg} alt="Frame" />
      <div className="bg" />
      {items.map((item, index) => {
        return (
          <img
            key={index}
            className={`panel ${index === activeIndex ? 'active' : ''}`}
            src={targetImg === 'primary' ? item.primaryImg : item.secondaryImg}
            alt="Theme"
          />
        );
      })}
    </div>
  );
};

export default Device;
