import { useState } from 'react';
import Section from '../Section';

interface Email {
  name?: string;
  phone?: string;
  storeName?: string;
  city?: string;
  message?: string;
}

const Contattaci = () => {
  const [email, setEmail] = useState<Email>({
    name: '',
    phone: '',
    storeName: '',
    city: '',
    message: ''
  });

  const onInputChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setEmail({ ...email, [name]: value });
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    openEmail();
  };

  const openEmail = () => {
    const message = `Nome:\n${email.name || '--'}\n\nTelefono:\n${
      email.phone || '--'
    }\n\nNegozio:\n${email.storeName || '--'}\n\nCittà:\n${
      email.city || '--'
    }\n\nMessaggio:\n${email.message || '--'}`;
    const mailto = `mailto:easyboo.app@gmail.com?subject=Richiesta info&body=${encodeURIComponent(
      message
    )}`;

    window.open(mailto, '_blank');
  };

  return (
    <Section id="contact">
      <>
        <div className="section__header container py-4">
          <div className="text-center">
            <h1 className="display-4 mb-2">Sei interessato? Contattaci!</h1>
            <h5>
              Se vuoi saperne di più, compila il form qui sotto.
              <br />
              Sarai contattato in breve tempo per maggiori informazioni
              <br />e per una demo del prodotto, gratuita e senza impegno.
            </h5>
          </div>
        </div>
        <div className="section__content container">
          <div className="form p-3">
            <form onSubmit={e => onFormSubmit(e)}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Nome e cognome"
                  name="name"
                  minLength={4}
                  onChange={onInputChange}
                  required
                />
                <label htmlFor="name">Nome</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Un telefono di contatto"
                  name="phone"
                  onChange={onInputChange}
                />
                <label htmlFor="phone">Telefono</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="storeName"
                  placeholder="Il nome del tuo negozio, salone, centro..."
                  name="storeName"
                  minLength={4}
                  onChange={onInputChange}
                  required
                />
                <label htmlFor="storeName">Nome negozio</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="Città o paese"
                  name="city"
                  onChange={onInputChange}
                />
                <label htmlFor="city">Città</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Lascia un commento..."
                  id="message"
                  style={{ height: '100px' }}
                  name="message"
                  onChange={onInputChange}
                ></textarea>
                <label htmlFor="message">Messaggio</label>
              </div>
              <div className="text-center mb-2">
                <small className="text-muted">
                  Cliccando Invia si aprirà il tuo client email con i campi
                  precompilati.
                </small>
              </div>
              <div className="text-center">
                <button className="btn btn-secondary px-5" type="submit">
                  Invia
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </Section>
  );
};

export default Contattaci;
