const FullScreen = ({ cover }: { cover: string }) => {
  return (
    <>
      <section
        className="section section--fullscreen parallax-cover"
        style={{ backgroundImage: 'url(' + cover + ')' }}
      ></section>
    </>
  );
};
export default FullScreen;
