import logoImg from './../../assets/logo-simbolo-2.png';

const links: { icon: string; link: string }[] = [
  { icon: 'envelope', link: 'mailto:easyboo.app@gmail.com' },
  { icon: 'facebook', link: 'https://www.facebook.com/easybooApp' },
  { icon: 'instagram', link: 'https://www.instagram.com/easyboo.app/' }
];

const appVersion = process.env.REACT_APP_VERSION;

const Footer = () => {
  return (
    <section className="footer">
      <div className="container py-5">
        <div className="footer__row">
          <img className="footer__logo logo" src={logoImg} alt="Logo Easyboo" />

          <div className="footer__caption">
            <span className="d-block">© 2021 Easyboo, Inc</span>
            <small className="d-block">Pavia, Italia</small>
          </div>

          <span className="footer__spacer"></span>

          <div className="footer__links d-flex">
            {links.map((item, index) => (
              <div className="mx-2" key={index}>
                <a
                  href={item.link}
                  className="text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className={'bi-' + item.icon}
                    style={{ fontSize: '1.4rem' }}
                  ></i>
                </a>
              </div>
            ))}
          </div>
        </div>
        {appVersion && (
          <div className="mt-3 text-center">
            <span className="app-v">{appVersion}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default Footer;
