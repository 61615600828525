import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

const Section = ({
  children,
  id,
  contrast,
  fullscreen,
  options
}: {
  children: JSX.Element;
  id: string;
  contrast?: boolean;
  fullscreen?: boolean;
  options?: { delay?: number };
}) => {
  const ref = useRef<HTMLElement>(null);

  const { inViewport, enterCount } = useInViewport(
    ref,
    { threshold: (options && options.delay) || 0.45 },
    { disconnectOnLeave: false },
    {}
  );

  return (
    <section
      ref={ref}
      id={'section-' + id}
      className={
        'section' +
        (inViewport || enterCount > 0 ? ' in-viewport' : '') +
        (contrast ? ' section--contrast' : '') +
        (fullscreen ? ' section--fullscreen' : '')
      }
    >
      {children}
    </section>
  );
};

export default Section;
