import imgGestionale from '../../assets/img-gestionale-discover-1.png';
import imgApp from '../../assets/img-app-discover-1.png';
import Section from '../Section';

const Scopri = () => {
  return (
    <>
      <Section id="discover" contrast>
        <div className="container text-center pt-5">
          <h2 className="display-6">
            Gestisci al meglio le attività del tuo salone
            <br />e le interazioni con i tuoi clienti
          </h2>
        </div>
      </Section>

      <Section id="discover-dashboard" options={{ delay: 0.2 }} contrast>
        <div className="container">
          <BlockGestionale></BlockGestionale>
        </div>
      </Section>

      <Section id="discover-app" options={{ delay: 0.2 }} contrast>
        <div className="container pb-5">
          <BlockApp></BlockApp>
        </div>
      </Section>
    </>
  );
};

export default Scopri;

const BlockGestionale = () => {
  return (
    <ScopriBlock
      id="dashboard"
      title="Gestionale"
      img={imgGestionale}
      imgWidth={730}
    >
      <p>
        Tutte le attività del tuo negozio vengono gestite in modo digitale e
        automatizzato tramite il software dedicato.
        <br />
        Non dovrai più preoccuparti dei compiti più noiosi e ripetivi e potrai
        dedicarti finalmente a quello che ti piace fare
      </p>
    </ScopriBlock>
  );
};

const BlockApp = () => {
  return (
    <ScopriBlock id="app" title="App" img={imgApp} imgWidth={420}>
      <p>
        I tuoi clienti possono, tramite app dedicata, prenotare nuovi
        appuntamenti
        <br /> in qualsiasi ora e in qualsiasi luogo, senza interrompere il tuo
        lavoro.
        <br />
        L'app è sincronizzata con il gestionale in tempo reale, così la tua
        agenda è sempre aggiornata e precisa.
      </p>
    </ScopriBlock>
  );
};

const ScopriBlock = ({
  id,
  title,
  img,
  imgWidth,
  children
}: {
  id: string;
  title: string;
  img: string;
  imgWidth: number;
  children?: JSX.Element;
}) => {
  return (
    <>
      <div className="text-center">
        <h4 className="ean-fade-bottom">{title}</h4>
        <div className="ean-fade-bottom">{children}</div>
        <div className="divider ean-grow-width"></div>
        <div className="text-center my-5 mx-3">
          <img
            className="ean-fade-bottom "
            style={{ maxWidth: '100%', width: `${imgWidth}px` }}
            src={img}
            alt={'Immagine ' + title}
          />
        </div>

        <a
          href={'#section-' + id}
          className="btn btn-sm btn-outline-primary ean-fade-bottom"
        >
          Scopri di più
        </a>
      </div>
    </>
  );
};
