import Intro from './sections/Intro/Intro';
import Gestionale from './sections/Gestionale/Gestionale';
import Scopri from './sections/Scopri/Scopri';
import Applicazione from './sections/Applicazione/Applicazione';
import Contattaci from './sections/Contattaci/Contattaci';
import Footer from './sections/Footer/Footer';

function App() {
  return (
    <>
      <Intro />
      <Scopri />
      <Gestionale />
      <Applicazione />
      <Contattaci />
      <Footer />
    </>
  );
}

export default App;
