import { useEffect, useState } from 'react';
import Navigation from './Navigation';
import Caption from './Caption';
import Gallery from './Gallery';
import { SlideItem } from '../../models/slide-item';

const Slider = ({
  slides,
  device
}: {
  slides: SlideItem[];
  device?: 'phone' | 'notebook' | 'desktop';
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [clicked, setClicked] = useState(false);

  const viewSlide = (index: number, fromClick?: boolean) => {
    if (fromClick === true) {
      setClicked(true);
    }

    setActiveIndex(index);
  };

  const viewPrevSlide = (fromClick?: boolean) => {
    if (activeIndex === 0) {
      viewSlide(slides.length - 1, fromClick);
    } else {
      viewSlide(activeIndex - 1, fromClick);
    }
  };

  const viewNextSlide = (fromClick?: boolean) => {
    if (activeIndex > slides.length - 2) {
      viewSlide(0, fromClick);
    } else {
      viewSlide(activeIndex + 1, fromClick);
    }
  };

  useEffect(() => {
    if (clicked) {
      return;
    }

    const timer = setTimeout(() => {
      viewNextSlide();
    }, 4000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <div className="slider">
        <div className="slider__row">
          <button
            className="btn btn-secondary slider__dir me-5"
            onClick={() => viewPrevSlide(true)}
          >
            <i className="bi bi-arrow-left"></i>
          </button>
          <div>
            <Gallery
              slides={slides}
              activeIndex={activeIndex}
              device={device || 'notebook'}
              viewPrevSlide={() => viewPrevSlide(true)}
              viewNextSlide={() => viewNextSlide(true)}
            />
          </div>

          <button
            className="btn btn-secondary slider__dir ms-5"
            onClick={() => viewNextSlide(true)}
          >
            <i className="bi bi-arrow-right"></i>
          </button>
        </div>
        <div className="my-5">
          <Navigation
            slides={slides}
            activeIndex={activeIndex}
            viewSlide={(index: number) => viewSlide(index, true)}
          />
        </div>
        <Caption slides={slides} activeIndex={activeIndex} />
      </div>
    </>
  );
};

export default Slider;
