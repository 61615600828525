import { useEffect, useState } from 'react';
import Device from './Device';
import Navigation from './Navigation';
import { ThemeItem } from '../../models/theme-item';

const ThemeViewer = ({ themes }: { themes: ThemeItem[] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [clicked, setClicked] = useState(false);

  const viewNextTheme = () => {
    if (activeIndex >= themes.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const viewTheme = (index: number) => {
    setClicked(true);
    setActiveIndex(index);
  };

  useEffect(() => {
    if (clicked) {
      return;
    }

    const timer = setTimeout(() => {
      viewNextTheme();
    }, 4000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <div className="theme-viewer">
        <div className="theme-viewer__gallery">
          <Device
            activeIndex={activeIndex}
            items={themes}
            targetImg="secondary"
          />
          <Device
            activeIndex={activeIndex}
            items={themes}
            targetImg="primary"
          />
        </div>

        <div className="mt-5">
          <Navigation
            items={themes}
            activeIndex={activeIndex}
            setActiveIndex={(index: number) => viewTheme(index)}
          />
        </div>
      </div>
    </>
  );
};
export default ThemeViewer;
