import { useEffect, useRef, useState } from 'react';
import desktopImg from './../../assets/device-frame-desktop-1.png';
import phoneImg from './../../assets/device-frame-iphone.png';
import { SlideItem } from '../../models/slide-item';

const Gallery = ({
  slides,
  activeIndex,
  device,
  viewPrevSlide,
  viewNextSlide
}: {
  slides: SlideItem[];
  activeIndex: number;
  device: 'phone' | 'notebook' | 'desktop';
  viewPrevSlide: () => void;
  viewNextSlide: () => void;
}) => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const SetPrevActiveIndex = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevActiveIndex = SetPrevActiveIndex(activeIndex);

  const handleTouchStart = (event: any) => {
    setTouchStart(event.targetTouches[0].clientX);
  };

  const handleTouchMove = (event: any) => {
    setTouchEnd(event.targetTouches[0].clientX);
  };

  const handleTouchEnd = (event: any) => {
    const offset = 100;

    if (touchStart - touchEnd > offset) {
      viewPrevSlide();
    } else if (touchStart - touchEnd < -offset) {
      viewNextSlide();
    }
  };

  return (
    <>
      <div
        className={`gallery gallery--${device}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="gallery__content">
          {/* immagine */}
          <img
            className="gallery__device"
            alt="Device img"
            src={device === 'phone' ? phoneImg : desktopImg}
          />
          {/* controlli direzione */}
          {/* <div className="gallery__frame gallery__frame--controls">
            <div
              className="gallery__prev-area"
              onClick={() => viewPrevSlide()}
            ></div>
            <div
              className="gallery__next-area"
              onClick={() => viewNextSlide()}
            ></div>
          </div> */}
          {/* slides */}
          <div className="gallery__frame">
            <div className="gallery__fakebar"></div>
            {slides.map((slide, index) => (
              <img
                key={index}
                src={slide.image}
                alt="Slide img"
                className={`gallery__slide${
                  index === activeIndex ? ' active' : ''
                }${index === prevActiveIndex ? ' prev-active' : ''}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
