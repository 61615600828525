import FullScreen from '../../components/FullScreen/FullScreen';
import Slider from '../../components/Slider/Slider';
import Section from '../Section';
import imgDashboardAgenda from './../../assets/gestionale-agenda.png';
import imgDashboardClienti from './../../assets/dashboard-clienti.png';
import imgDashboardCassa from './../../assets/dashboard-cassa.png';
import imgDashboardEventi from './../../assets/dashboard-eventi.png';
import imgDashboardMagazzino from './../../assets/dashboard-magazzino.png';
import imgDashboardServizi from './../../assets/dashboard-servizi.png';
import imgDashboardStaff from './../../assets/dashboard-staff.png';
import imgDashboardStats from './../../assets/dashboard-stats.png';
import fullscreenImg from './../../assets/img-fullscreen-1.jpg';

const slides = [
  {
    image: imgDashboardAgenda,
    title: 'Agenda',
    paragraph:
      "In primo piano l'agenda della giornata, dove puoi consultare in modo rapido e intuitivo gli appuntamenti in programma. Per ognuno è possibile visualizzare la scheda di dettaglio, con tutte le informazioni utili."
  },
  {
    image: imgDashboardClienti,
    title: 'Anagrafica dei clienti',
    paragraph:
      'Per ogni cliente puoi visualizzare tutte le informazioni utili, lo storico degli appuntamenti, il credito accumulato e altro ancora.'
  },
  {
    image: imgDashboardCassa,
    title: 'Gestione della cassa',
    paragraph:
      "Puoi tenere sotto controllo le entrate e amministrare l'attività fiscale del tuo negozio"
  },
  {
    image: imgDashboardMagazzino,
    title: 'Gestione del magazzino',
    paragraph:
      'Puoi verificare la quantità di prodotti disponibili in negozio ed effettuare ordini presso i tuoi fornitori'
  },
  {
    image: imgDashboardStaff,
    title: 'Gestione del personale',
    paragraph:
      'Puoi organizzare gli orari di lavoro e definire i servizi a cui è abilitato ciascun membro dello staff'
  },
  {
    image: imgDashboardStats,
    title: 'Statistiche',
    paragraph:
      'Misura la crescita del tuo negozio e il rendimento dei tuoi servizi tramite grafici accurati'
  },
  {
    image: imgDashboardEventi,
    title: 'Eventi',
    paragraph:
      'Puoi inserire i giorni di chiusura del negozio e gestire le ferie, i giorni di malattia e i permessi richiesti dai tuoi collaboratori'
  },
  {
    image: imgDashboardServizi,
    title: 'Catalogo dei servizi offerti',
    paragraph:
      'Puoi definire i servizi offerti dal tuo negozio indicando il prezzo, la durata e il personale abilitato'
  }
];

const Gestionale = () => {
  return (
    <>
      <FullScreen cover={fullscreenImg} />
      <Section id="dashboard">
        <div className="container">
          <div className="section__header mb-5">
            <h1 className="display-2">Scopri il gestionale</h1>
            <div className="divider ean-grow-width"></div>
            <h5 className="mb-0">
              Un software super completo direttamente sul tuo computer, senza
              installazioni, basta il browser.
            </h5>
          </div>
          <div className="section__content py-5">
            <Slider slides={slides} device="desktop" />
          </div>
        </div>
      </Section>
    </>
  );
};

export default Gestionale;
