import logoEasyBoo from './../../assets/logo-esteso.svg';
import imgScroll from './../../assets/scroll.svg';

const Intro = () => {
  return (
    <section
      id="section-intro"
      className="section section--fullscreen parallax-cover"
    >
      <div className="topbar py-2">
        <div className="container">
          <a href="/dashboard/" className="btn btn-link btn-sm me-3">
            <i className="bi bi-box-arrow-right"></i>&nbsp; Accedi
          </a>
          <a href="#section-contact" className="btn btn-link btn-sm">
            <i className="bi bi-envelope-open"></i>&nbsp; Contattaci
          </a>
        </div>
      </div>
      <div className="container">
        <img className="title" src={logoEasyBoo} alt="Logo Easyboo" />
        <h3 className="display-3 subtitle">
          <span>Book it.</span>
          <span> Easy!</span>
        </h3>
      </div>
      <a href="#section-discover" className="cta">
        <img src={imgScroll} alt="Icona scroll" />
      </a>
    </section>
  );
};

export default Intro;
