import FullScreen from '../../components/FullScreen/FullScreen';
import Slider from '../../components/Slider/Slider';

import tema1a from './../../assets/Tema 1 a.png';
import tema1b from './../../assets/Tema 1 b.png';
import tema2a from './../../assets/Tema 2 a.png';
import tema2b from './../../assets/Tema 2 b.png';
import tema3a from './../../assets/Tema 3 a.png';
import tema3b from './../../assets/Tema 3 b.png';
import tema4a from './../../assets/Tema 4-1 a.png';
import tema4b from './../../assets/Tema 4-1 b.png';
import tema5a from './../../assets/Tema 5 a.png';
import tema5b from './../../assets/Tema 5 b.png';
import tema6a from './../../assets/Tema 6 a.png';
import tema6b from './../../assets/Tema 6 b.png';

import imgAppHome from './../../assets/app-home.png';
import imgAppPrenota from './../../assets/app-prenota.png';
import imgAppProdotti from './../../assets/app-prodotti.png';
import imgAppNegozio from './../../assets/app-negozio.png';
import imgAppProfilo from './../../assets/app-profilo.png';
import imgAppPortafoglio from './../../assets/app-portafoglio.png';
import imgAppGestioneOspiti from './../../assets/app-gestione-ospiti.png';

import fullscreenImg from './../../assets/img-fullscreen-telefoni.png';

import ThemeViewer from '../../components/ThemeViewer/ThemeViewer';
import { ThemeItem } from '../../models/theme-item';
import { SlideItem } from '../../models/slide-item';
import Section from '../Section';

const slides: SlideItem[] = [
  {
    image: imgAppHome,
    title: 'Home',
    paragraph:
      'Le cose importanti sempre in primo piano: prossimo appuntamento,nuova prenotazione, catalogo prodotti, promozioni...'
  },
  {
    image: imgAppPrenota,
    title: 'Nuova prenotazione',
    paragraph:
      'Una procedura semplice e chiara guida il cliente nella prenotazione di un nuovo appuntamento: scelta del servizio, operatore e orario desiderato'
  },
  {
    image: imgAppProdotti,
    title: 'Catalogo prodotti',
    paragraph:
      'Accesso al catalogo dei prodotti con possibilità di aggiungerli alla prenotazione e ritirarli successivamente in negozio'
  },
  {
    image: imgAppNegozio,
    title: 'Negozio',
    paragraph:
      'Una vetrina digitale sempre aggiornata per far conoscere il tuo negozio e il tuo staff'
  },
  {
    image: imgAppProfilo,
    title: 'Area personale',
    paragraph:
      'Gestione dei dati personali e visione dello storico degli appuntamenti'
  },
  {
    image: imgAppPortafoglio,
    title: 'Portafoglio',
    paragraph:
      'Il cliente può tenere sotto controllo il credito disponibile e i punti accumulati'
  },
  {
    image: imgAppGestioneOspiti,
    title: 'Gestione ospite',
    paragraph:
      "É possibile prenotare e gestire un appuntamento per un componente della propria famiglia che non dispone dell'app "
  }
];

const themes: ThemeItem[] = [
  {
    primaryImg: tema2a,
    secondaryImg: tema2b,
    title: 'Sunrise blue',
    primaryColor: '#e8694c',
    secondaryColor: '#632720'
  },
  {
    primaryImg: tema4a,
    secondaryImg: tema4b,
    title: 'Desert white',
    primaryColor: '#dfdad7',
    secondaryColor: '#3c5074'
  },
  {
    primaryImg: tema1a,
    secondaryImg: tema1b,
    title: 'Sunset black',
    primaryColor: '#ea8b3a',
    secondaryColor: '#f0cc67'
  },
  {
    primaryImg: tema3a,
    secondaryImg: tema3b,
    title: 'Woods green',
    primaryColor: '#f3ce59',
    secondaryColor: '#41597d'
  },
  {
    primaryImg: tema5a,
    secondaryImg: tema5b,
    title: 'Ocean blue',
    primaryColor: '#ddeffa',
    secondaryColor: '#3c5074'
  },
  {
    primaryImg: tema6a,
    secondaryImg: tema6b,
    title: 'Mountain brown',
    primaryColor: '#eae9e1',
    secondaryColor: '#3c5074'
  }
];

const Applicazione = () => {
  return (
    <>
      <FullScreen cover={fullscreenImg} />

      <Section id="app">
        <div className="container">
          <div className="section__header mb-5">
            <h1 className="display-2">Scopri l'app</h1>
            <div className="divider ean-grow-width"></div>
            <h5 className="mb-0">
              Offri ai tuoi clienti un'esperienza che va oltre la semplice
              prenotazione
            </h5>
          </div>
          <div className="section__content py-5">
            <Slider slides={slides} device="phone" />
          </div>
        </div>
      </Section>

      <Section id="theme" contrast>
        <div className="container">
          <div className="section__header mb-5">
            <h1 className="display-2">Fatta su misura</h1>
            <div className="divider ean-grow-width"></div>
            <h5 className="mb-0">
              Easyboo è la prima app che riflette al meglio lo stile del tuo
              negozio:
              <br /> scegli tra i temi di tendenza oppure crea un tema esclusivo
              fatto su misura per te
            </h5>
          </div>
          <div className="section__content py-5">
            <ThemeViewer themes={themes}></ThemeViewer>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Applicazione;
