import { SlideItem } from '../../models/slide-item';

const Caption = ({
  slides,
  activeIndex
}: {
  slides: SlideItem[];
  activeIndex: number;
}) => {
  return (
    <>
      <div className="caption">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`caption__slide ${
              index === activeIndex ? ' active' : ''
            }`}
          >
            <h1 key={index}>{slide.title}</h1>
            <p className="mb-0">{slide.paragraph}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Caption;
